import React from 'react';
import '../assets/css/main.scss';
import Layout from '../components/layout/layout';
import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import Hero from '../components/hero/hero';
import Work from '../components/work/work';

const Portfolio = () => {
  const { language } = useI18next();
  return (
    <Layout pageTitle={ language === 'nl' ? 'Bekijk mijn laatste werk' : 'View my recent work' }>
      <>
        <Hero size={'small'}/>
        {
          language === 'nl'&&
          <header>
            <h1>Portfolio <span>Bekijk mijn laatste werk</span></h1>
          </header>
        }
        {
          language === 'en'&&
          <header>
            <h1>Portfolio <span>View my recent work</span></h1>
          </header>
        }
        <Work/>
      </>
    </Layout>
  );
};
export default Portfolio;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
