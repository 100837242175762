import React from 'react';
import './work.scss';
import {StaticImage} from 'gatsby-plugin-image';
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
// @ts-ignore
import HomeyIcon from '../../assets/icons/homey.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CallToAction from '../cta/cta';

const Work = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <div className="portfolio">

      <div className="work-page" id="smpl">
        <StaticImage
          className="work-image"
          objectPosition="center"
          objectFit="contain"
          src="../../assets/images/smpl.jpg"
          alt="smpl homey app"
        />
        <div className={'work-content'}>
          <h2>smpl Homey app</h2>
          {
            language === 'nl'&&<p>Je kunt nu data visualiseren van Homey naar Smpl displays.</p>
          }
          {
            language === 'en'&&<p>You can now visualize data from Homey to Smpl displays.</p>
          }

          <div className={'rating'}>
            <div className={'stars'}>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
            </div>
            <div className={'quote'}>
              <blockquote>"After connecting it to Homey and made some settings and a Flow, it works perfectly. A
                musthave!"
              </blockquote>
              <figcaption>
                <h1>
                  <span>Marcel B</span>
                  <span>{t('work.homey_owner')}</span>
                </h1>
              </figcaption>
            </div>
          </div>
        </div>
      </div>

      <div className="work-page" id="leviton">
        <StaticImage
          className="work-image"
          objectPosition="center"
          objectFit="contain"
          src="../../assets/images/leviton-app.png"
          alt="Leviton homey app"
        />
        <div className={'work-content'}>
          <h2>Leviton Homey app</h2>
          <p>Decora Smart Z-Wave Plus.</p>
        </div>
      </div>

      <div className="work-page" id="sureflap">
        <StaticImage
          className="work-image"
          objectPosition="center"
          objectFit="contain"
          src="../../assets/images/sureflap-app.png" alt="Sureflap homey app"
        />
        <div className={'work-content'}>
          <h2>Sureflap Homey app</h2>
          {
            language === 'nl'&&<p>Een slimme manier om uw huisdieren veilig en gezond te houden</p>
          }
          {
            language === 'en'&&<p>A smart way to keep your pets safe and healthy</p>
          }

          <div className={'rating'}>
            <div className={'stars'}>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
            </div>
            <div className={'quote'}>
              <blockquote>"The App works great with my two Surefeed Petfeeder! Thanks a lot for your great work!"
              </blockquote>
              <figcaption>
                <h1>
                  <span>Stefan A</span>
                  <span>{t('work.homey_owner')}</span>
                </h1>
              </figcaption>
            </div>
          </div>
        </div>
      </div>

      <div className="work-page" id="xiaomi-mi-flora">
        <StaticImage
          className="work-image"
          objectPosition="center"
          objectFit="contain"
          src="../../assets/images/xiaomi-mi-flora.png"
          alt="Xiaomi mi flora homey app"
        />
        <div className={'work-content'}>
          <h2>Xiaomi mi flora homey app</h2>
          {
            language === 'nl'&&<p>Volg de behoefte van uw planten en bloemen</p>
          }
          {
            language === 'en'&&<p>Follow the needs of your plants and flowers </p>
          }

          <div className={'rating'}>
            <div className={'stars'}>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
            </div>
            <div className={'quote'}>
              <blockquote>"Great app, it’s measurement refresh process is fully effective since the app automatic
                update."
              </blockquote>
              <figcaption>
                <h1>
                  <span>Hervé P</span>
                  <span>{t('work.homey_owner')}</span>
                </h1>
              </figcaption>
            </div>
          </div>
        </div>
      </div>

      <div className="work-page" id="beacon">
        <StaticImage
          className="work-image"
          objectPosition="center"
          objectFit="contain"
          src="../../assets/images/beacon-app.png"
          alt="Beacon homey app"
        />
        <div className={'work-content'}>
          <h2>Beacon homey app</h2>
          {
            language === 'nl'&&<p>Detecteer uw aanwezigheid met BLE beacons</p>
          }
          {
            language === 'en'&&<p>Detect your presence with BLE beacons</p>
          }

          <div className={'rating'}>
            <div className={'stars'}>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
              <span className={'star star--checked'}><FontAwesomeIcon icon={['fas', 'star']}/></span>
            </div>
            <div className={'quote'}>
              <blockquote>"Working great with several devices."</blockquote>
              <figcaption>
                <h1>
                  <span>Ruud M</span>
                  <span>{t('work.homey_owner')}</span>
                </h1>
              </figcaption>
            </div>
          </div>

        </div>
      </div>

      <div className="work-page" id="your-project?">
        <StaticImage
          className="work-image"
          objectPosition="center"
          objectFit="contain"
          src="../../assets/images/your-project.png"
          alt="Your project"
        />
        <div className={'work-content'}>
          <CallToAction>
            <>
              {
                language === 'nl'&&
                <>
                  <h2>Uw project of concept?</h2>
                  <h3>Bent u benieuwd hoe ik u hierbij kan helpen?</h3>
                </>
              }
              {
                language === 'en'&&
                <>
                  <h2>Your project or concept?</h2>
                  <h3>Are you curious how I can help you with this?</h3>
                </>
              }
            </>
          </CallToAction>
        </div>
      </div>

    </div>
  );
};
export default Work;
